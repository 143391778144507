import React from 'react';
import { Link } from 'react-router-dom';

// Define proper type for styles using React.CSSProperties
const styles: { [key: string]: React.CSSProperties } = {
  container: {
    textAlign: 'center', // textAlign expects specific values like 'center', 'left', 'right', etc.
    marginTop: '50px',
  },
  title: {
    fontSize: '48px',
    fontWeight: 'bold',
    color: '#ff0000',
  },
  message: {
    fontSize: '20px',
    color: '#555',
  },
  link: {
    display: 'inline-block',
    marginTop: '20px',
    fontSize: '18px',
    color: '#007bff',
    textDecoration: 'none',
  },
};

const NotFound: React.FC = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>404 - Page Not Found</h1>
      <p style={styles.message}>
        Oops! The page you're looking for doesn't exist.
      </p>
      <Link to="/" style={styles.link}>
        Go back to Home
      </Link>
    </div>
  );
};

export default NotFound;
