import React from 'react';
import './Card.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as solidStar, faStarHalfAlt as halfStar, faStar as regularStar } from '@fortawesome/free-solid-svg-icons';

interface CardProps {
  id: number;
  title: string;
  description: string;
  book: string;
  cover: string;
  rating: number; // Update to be a number
  authorname: string;
  onClick: (id: number) => void;
}

const Card: React.FC<CardProps> = ({ id, title, description, book, cover, rating, authorname, onClick }) => {
  const getStarRating = (rating: number) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;
    const totalStars = 5; // Total number of stars

    return Array.from({ length: totalStars }, (_, index) => {
      if (index < fullStars) {
        return <FontAwesomeIcon key={index} icon={solidStar} className="star" />;
      }
      if (index === fullStars && hasHalfStar) {
        return <FontAwesomeIcon key={index} icon={halfStar} className="star" />;
      }
      return <FontAwesomeIcon key={index} icon={regularStar} className="star empty" />;
    });
  };

  return (
    <div
      className="card p-2"
      onClick={() => onClick(id)}
    >
      <div className="card-cover-container">
        <img
          src={cover}
          alt={title}
          className="card-cover"
        />
      </div>
      <div className="card-content">
      <p className="card-title pt-2 overflow-hidden text-ellipsis whitespace-nowrap">{title}</p>
      <p className="card-author pt-1">{authorname}</p>
        <div className="card-rating-container pt-1">
          {getStarRating(rating)}
        </div>
        <p className="card-edition pt-1">ekindle Edition</p>
        <p className="card-price">Free</p>
        {/* <p className="card-description">{description}</p> */}
      </div>
    </div>
  );
};

export default Card;
