import { faChevronLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useRef } from 'react';
import { ReactReader, ReactReaderStyle, type IReactReaderStyle } from 'react-reader';
import { useSwipeable } from 'react-swipeable';
import Lottie from 'lottie-react';
import loadingAnimation from '../../assets/ebook_loading.json';


// Define the themes
const lightReaderTheme: IReactReaderStyle = {
  ...ReactReaderStyle,
  readerArea: {
    ...ReactReaderStyle.readerArea,
    color: '#333',
    backgroundColor: '#fff',
    fontFamily: 'Georgia, serif',
  },
  titleArea: {
    ...ReactReaderStyle.titleArea,
    color: '#333',
  },
  tocArea: {
    ...ReactReaderStyle.tocArea,
    background: '#f1f1f1',
  },
  tocButtonExpanded: {
    ...ReactReaderStyle.tocButtonExpanded,
    background: '#ddd',
  },
  tocButtonBar: {
    ...ReactReaderStyle.tocButtonBar,
    background: '#0f172a',
  },
  tocButton: {
    ...ReactReaderStyle.tocButton,
    color: '#0f172a',
  },
};

const darkReaderTheme: IReactReaderStyle = {
  ...ReactReaderStyle,
  readerArea: {
    ...ReactReaderStyle.readerArea,
    backgroundColor: '#1e1e1e',
    color: '#eaeaea',
    fontFamily: 'Georgia, serif',
  },
  titleArea: {
    ...ReactReaderStyle.titleArea,
    color: '#eaeaea',
  },
  tocArea: {
    ...ReactReaderStyle.tocArea,
    background: '#2d2d2d',
  },
  tocButtonExpanded: {
    ...ReactReaderStyle.tocButtonExpanded,
    background: '#3a3a3a',
  },
  tocButtonBar: {
    ...ReactReaderStyle.tocButtonBar,
    background: '#f8fafc',
  },
  tocButton: {
    ...ReactReaderStyle.tocButton,
    color: '#f8fafc',
  },
};

interface CardProps {
  Bookurl: string;
  close: () => void;
}

const Epubviewer: React.FC<CardProps> = ({ Bookurl, close }) => {
  const [location, setLocation] = useState<string | number>(0);
  const [theme, setTheme] = useState<'light' | 'dark'>('light');
  const [zoom, setZoom] = useState<number>(100);
  const [isZoomDrawerOpen, setIsZoomDrawerOpen] = useState(false);
  const renditionRef = useRef<any>(null);
  const readerRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(true); // Loading state


  const updateTheme = (rendition: any, theme: 'light' | 'dark') => {
    if (rendition && rendition.themes) {
      const themes = rendition.themes;
      switch (theme) {
        case 'dark':
          themes.override('color', '#eaeaea');
          themes.override('background', '#1e1e1e');
          break;
        case 'light':
          themes.override('color', '#333');
          themes.override('background', '#fff');
          break;
      }
    }
  };

  useEffect(() => {
    if (renditionRef.current) {
      renditionRef.current.themes.fontSize(`${zoom}%`);
      updateTheme(renditionRef.current, theme);
    }
  }, [theme, zoom]);

  const handleZoomChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newZoom = parseInt(event.target.value, 10);
    setZoom(newZoom);
  };

  const toggleZoomDrawer = () => {
    setIsZoomDrawerOpen(!isZoomDrawerOpen);
  };

  const { ref, ...handlers } = useSwipeable({
    onSwipedLeft: () => {
      renditionRef.current?.next();
    },
    onSwipedRight: () => {
      renditionRef.current?.prev();
    },
    trackMouse: true,
  });

  const goBack = () => {
    renditionRef.current?.prev(); // Navigate to the previous page
  };

  const handleLoad = () => {
    setIsLoading(false); // Set loading to false when content is loaded
  };

  return (
    <>
      <div className="epub-container">
        <div className="controls">
        <div className='pt-4 pb-2 ps-4'>
            <button 
            onClick={close} 
            className=" text-gray-600 hover:text-gray-900 transition duration-300 flex items-center"
        >
            <FontAwesomeIcon icon={faChevronLeft} className='pe-1'/>
            Go back
        </button>
        </div>

          <div className="zoom-controls pe-4">
            <button onClick={toggleZoomDrawer} className="zoom-button">
              {/* Zoom {isZoomDrawerOpen ? '-' : '+'} */}
              Aa
            </button>
            {/* <button onClick={close} className="close-button" aria-label="Close Viewer">
              <FontAwesomeIcon icon={faTimes} />
            </button> */}
          </div>
        </div>

        {isZoomDrawerOpen && (
          <div className="zoom-drawer">
            <span className='pe-2'>A</span>
            <input
              type="range"
              min="50"
              max="200"
              value={zoom}
              onChange={handleZoomChange}
              className="zoom-slider cursor-grab"
            />
            <span className='text-2xl	'>A</span>
          </div>
        )}

      {isLoading && (
        <div className="loading-view">
          <Lottie 
            animationData={loadingAnimation} // Use the imported variable
            loop 
            autoplay 
            style={{ width: '200px', height: '200px' }} 
          />
        </div>
      )}

        <div
          className="reader-container"
          ref={(node) => {
            readerRef.current = node;
            ref(node);
          }}
          {...handlers}
          style={{
            overflow: 'hidden',
            userSelect: 'none',
          }}
        >
          <ReactReader
            url={Bookurl}
            location={location}
            locationChanged={(epubcfi: string) => setLocation(epubcfi)}
            readerStyles={lightReaderTheme}
            getRendition={(rendition) => {
              renditionRef.current = rendition;
              updateTheme(rendition, theme);
              handleLoad();
            }}
            epubInitOptions={{
              openAs: 'epub',
            }}
            epubOptions={{
              allowPopups: true,
              allowScriptedContent: true,
            }}
            swipeable={true}
          />
        </div>
      </div>
      <style>
        {`
          .epub-container {
            display: flex;
            flex-direction: column;
            height: 100vh;
            width: 100vw;
            background: #fff;
          }
          .controls {
            display: flex;
            justify-content: space-between; /* Aligns controls to the right */
            padding: 5px;
            background: #fff;
            border-bottom: 1px solid #f5f6f9;
            border-top: 1px solid #f5f6f9;
          }
          .zoom-controls {
            display: flex;
            align-items: center;
          }
          .zoom-button, .close-button {
            margin: 5px;
            padding: 10px;
            font-size: 16px;
            cursor: pointer;
            border: 1px solid #ccc;
            border-radius: 5px;
            // background: #f4f4f4;
          }
          .zoom-button:hover, .close-button:hover {
            background: #ddd;
          }
          .zoom-drawer {
            background: #f9f9f9;
            padding: 10px;
            border: 1px solid #ccc;
            margin: 10px;
            position: absolute;
            right: 10px;
            top: 50px; /* Adjust as needed for spacing */
            box-shadow: 0 2px 10px rgba(0,0,0,0.1);
            z-index: 12
          }
          .zoom-slider {
            margin-right: 10px;
            width: 150px;
          }
          .reader-container {
            flex-grow: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            background: #fff;
            overflow: auto; 
            padding-bottom: 25px
          }
          .reader-container > div {
            width: 100%;
            height: 100%;
          }
          .loading-view {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%; /* Full height while loading */
          }
        `}
      </style>
    </>
  );
};

export default Epubviewer;
