import React, { useState, useEffect } from 'react';
import './FileUploadForm.css'; // Import the CSS file
import { tailChase } from 'ldrs';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

const FileUploadForm: React.FC = (props: any) => {
  const { onClose } = props;
  const [coverImage, setCoverImage] = useState<File | null>(null);
  const [epubFile, setEpubFile] = useState<File | null>(null);
  const [title, setTitle] = useState('');
  const [releaseDate, setReleaseDate] = useState<string>(new Date().toISOString().split('T')[0]); // Set default to today’s date
  const [publishingDate, setPublishingDate] = useState('');
  const [rating, setRating] = useState<number | ''>('');
  const [details, setDetails] = useState('');
  const [authorname, setAuthorname] = useState('');
  const [language, setLanguage] = useState('');
  const [tags, setTags] = useState(''); 
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isLoading, setLoading] = useState(false);

  tailChase.register();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    // Validate fields
    if (!coverImage || !epubFile || !title || !releaseDate || rating === '' || isNaN(rating) || !authorname || !language || !tags) {
      setError('Please fill out all fields and upload both files.');
      setLoading(false);
      return;
    }
    const epubFileSize = epubFile.size; // Size in bytes

    const formData = new FormData();
    formData.append('title', title);
    formData.append('release_date', releaseDate);
    formData.append('publishingdate', publishingDate); // Append publishing date
    formData.append('rating', rating.toString());
    formData.append('details', details);
    formData.append('authorname', authorname);
    formData.append('language', language);
    formData.append('tags', tags); // Use tags instead of genre
    formData.append('coverImage', coverImage);
    formData.append('epubFile', epubFile);
    formData.append('epubFileSize', epubFileSize.toString())

    try {
      const response = await fetch('https://workers.younus-tao-ai.workers.dev/upload', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        setSuccess('Files and metadata uploaded successfully!');
        setTitle('');
        setReleaseDate('');
        setRating('');
        setDetails('');
        setAuthorname('');
        setLanguage('');
        setTags('');
        setCoverImage(null);
        setEpubFile(null);
        setLoading(false);
      } else {
        throw new Error('Failed to upload files');
      }
    } catch (error: any) {
      setError(error.message);
      setLoading(false);
    }
  };

  return (
    <>
      <header className="bg-blue-600 text-white p-4 w-full mt-auto">
        <nav className="w-full md:w-auto flex items-center justify-center gap-2 flex-wrap">
          {[
            ['Home', '/'],
            ['Uploadebook', '/FileUploadForm'],
          ].map(([label, link], key) => (
            <NavLink
              to={link}
              key={key}
              className={({ isActive }) =>
                cx('hover:underline', { underline: isActive })
              }
            >
              {label}
            </NavLink>
          ))}
        </nav>
      </header>
      <form onSubmit={handleSubmit} className="upload-form">
        <h1>Upload Files</h1>
        {error && <div className="error-message">{error}</div>}
        {success && <div className="success-message">{success}</div>}
        <div className="form-group">
          <label>
            Title:
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </label>
        </div>
        {/* <div className="form-group">
          <label>
            Release Date:
            <input
              type="date"
              value={releaseDate}
              onChange={(e) => setReleaseDate(e.target.value)}
              required
            />
          </label>
        </div> */}
        <div className="form-group">
          <label>
            Rating (1-5):
            <input
              type="number"
              value={rating}
              onChange={(e) => setRating(parseFloat(e.target.value))}
              step="0.1" // Allows decimal numbers
              min="0"    // Set a minimum value if necessary
              required
            />
          </label>
        </div>
        <div className="form-group">
        <label>
          Description:
          <textarea 
            value={details}
            onChange={(e) => setDetails(e.target.value)}  
            className="resize-none w-full border border-gray-300 rounded p-2 h-60"
          />
        </label>
      </div>
        <div className="form-group">
          <label>
            Author Name:
            <input
              type="text"
              value={authorname}
              onChange={(e) => setAuthorname(e.target.value)}
              required
            />
          </label>
        </div>
        <div className="form-group">
          <label>
            Language:
            <input
              type="text"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              required
            />
          </label>
        </div>
        <div className="form-group">
          <label>
            Tags (comma-separated):
            <input
              type="text"
              value={tags}
              onChange={(e) => setTags(e.target.value)}
              required
            />
          </label>
        </div>
        <div className="form-group">
        <label>
          Publishing Date:
          <input
            type="date"
            value={publishingDate}
            onChange={(e) => setPublishingDate(e.target.value)}
            required
          />
        </label>
      </div>
        <div className="form-group">
          <label>
            Cover Image:
            <input
              type="file"
              accept="image/*"
              onChange={(e) => setCoverImage(e.target.files ? e.target.files[0] : null)}
              required
            />
          </label>
        </div>
        <div className="form-group">
          <label>
            EPUB File:
            <input
              type="file"
              accept=".epub"
              onChange={(e) => setEpubFile(e.target.files ? e.target.files[0] : null)}
              required
            />
          </label>
        </div>
        <div className='pt-5'>
          <div className='main-btn'>
            <button type="submit" className="submit-button">
              {isLoading ? (
                <l-tail-chase
                  size="25"
                  speed="1.75"
                  color="black"
                ></l-tail-chase>
              ) : (
                "Upload"
              )}
            </button>
          </div>
        </div>
      </form>
      <footer className="bg-blue-600 text-white p-4 w-full mt-auto">
        <div className="text-center">
          &copy; 2024 ekindlelibrary
        </div>
      </footer>
    </>
  );
};

export default FileUploadForm;
