import React from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './Home';
import './index.css';
import { BrowserRouter, Route, NavLink, Routes } from 'react-router-dom'
import HomePage from './components/Home/HomePage';
import FileUploadForm from './components/form/UploadForm';
import Epubviewer from './components/content/Epubviewer';
import BookDetails from './components/content/BookDetails';
import BookDetailsContainer from './components/content/BookDetailsContainer';
import GoogleAnalytics from './GoogleAnalytics';
import { Analytics } from "@vercel/analytics/react"
import Contact from './components/content/Contact';
import NotFound from './components/NotFound';

function App() {
  return (
    <>
    <GoogleAnalytics />
    <Analytics />
    <BrowserRouter>
      <div className="relative min-h-screen bg-stone-100 flex flex-col">
        {/* Main Content */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/FileUploadForm" element={<FileUploadForm />} />
            <Route path='/Bookdetails' element={<BookDetailsContainer/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="*" element={<NotFound />} />
          </Routes>
      </div>
    </BrowserRouter>
  </>
  );
}

export default App;
