// src/GoogleAnalytics.tsx

import { useEffect } from 'react';

const GoogleAnalytics: React.FC = () => {
  useEffect(() => {
    const loadGtagScript = () => {
      const script = document.createElement('script');
      script.src = 'https://www.googletagmanager.com/gtag/js?id=G-3PSR2ET3EF';
      script.async = true;
      document.head.appendChild(script);
    };

    const initGtag = () => {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function () {
        window.dataLayer.push(arguments);
      };
      window.gtag('js', new Date());
      window.gtag('config', 'G-3PSR2ET3EF');
    };

    loadGtagScript();
    initGtag();

    return () => {
      const scriptTags = document.querySelectorAll('script[src="https://www.googletagmanager.com/gtag/js?id=G-3PSR2ET3EF"]');
      scriptTags.forEach(script => script.remove());
    };
  }, []);

  return null; // This component doesn't render anything
};

export default GoogleAnalytics;
