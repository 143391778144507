import React, { useState } from 'react';
import CardContainer from './components/content/CardContainer';
import { BrowserRouter, Route, NavLink, Routes } from 'react-router-dom';
import cx from 'classnames';
import FileUploadForm from './components/form/UploadForm';
import GenreSlider from './components/siderbar/GenreSlider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faEnvelope, faHome, faUpload } from '@fortawesome/free-solid-svg-icons';
import "../src/components/siderbar/GenreSlider.css";
import Contact from './components/content/Contact';

const tags = [
  { id: 1, name: 'Erotic Romance' },
  { id: 2, name: 'Fiction' },
  { id: 3, name: 'Non-Fiction' },
  { id: 4, name: 'Science Fiction' },
  { id: 5, name: 'Fantasy' },
  { id: 6, name: 'Classics' },
  { id: 7, name: 'Romance' },
  { id: 8, name: 'Historical' },
];

const Home: React.FC = () => {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State for sidebar visibility

  const handleFilterChange = (selectedTags: string[]) => {
    setSelectedTags(selectedTags);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(prev => !prev); // Toggle sidebar visibility
  };

  return (
    <div className="relative min-h-screen bg-stone-100 flex flex-col">
      {/* Header */}
      <header className="bg-blue-600 text-white p-4 w-full shadow-md">
        <nav className="container mx-auto flex items-center justify-between flex-wrap">
          <div className="flex items-center gap-4">
          {/* <button className="md:hidden" onClick={toggleSidebar}>
              <FontAwesomeIcon icon={faBars} className="text-white" />
            </button> */}
            <img className='w-12 h-10' src="/eicon.png" alt="logo" />
            <NavLink to="/Home" className="text-xl font-semibold">
              ekindleLibrary
            </NavLink>
          </div>
          <div className="flex gap-4 items-center">
          {[
            // { label: 'Home', link: '/', icon: faHome },
            // { label: 'Upload Book', link: '/FileUploadForm', icon: faUpload },
            // { label: 'Contact us', link: '/Contact', icon: faUpload },

          ].map(({ label, link, icon }, index) => (
            <NavLink
              to={link}
              key={index}
              className={({ isActive }) =>
                cx('flex items-center gap-2 hover:underline', { 'font-bold': isActive })
              }
            >
              <FontAwesomeIcon icon={icon} className="" /> {/* Icon */}
              {label}
            </NavLink>
          ))}
        </div>
        </nav>
      </header>
      
      {/* Main Content */}
      <main className="md:flex-grow md:flex main-content">
        <aside className={"md:w-64 px-2 pt-2 overflow-y-auto md:bg-white transition-transform duration-300"}>
          <GenreSlider tags={tags} onFilterChange={handleFilterChange} />
        </aside>
        <section className="flex-grow">
          <div className="p-4">
            <input
              type="text"
              placeholder="Search by title or author"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="rounded text-black w-full md:mt-4"
            />
            <Routes>
              <Route path="/FileUploadForm" element={<FileUploadForm />} />
              <Route path="/Contact" element={<Contact />} />
              <Route path="" element={<CardContainer selectedTags={selectedTags} searchTerm={searchTerm} />} />
            </Routes>
          </div>
        </section>
      </main>
      
      {/* Footer */}
        <footer className="bg-blue-600 text-white p-4 w-full mt-auto">
        <div className="flex justify-center mt-2">
          <NavLink to="/contact" className="flex items-center gap-2 hover:underline"> {/* Update to match App.tsx route */}
            <FontAwesomeIcon icon={faEnvelope} /> Contact us
          </NavLink>
        </div>
        <div className="text-center pt-2">
          &copy; 2024 ekindlelibrary
        </div>
      </footer>
    </div>
  );
};

export default Home;
