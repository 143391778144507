import React, { useState } from 'react';
import { Book } from './types';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as solidStar, faStarHalfAlt as halfStar, faStar as regularStar, faCalendarAlt, faEarthAsia, faFileZipper, faHome, faUpload, faChevronLeft, faEnvelope } from '@fortawesome/free-solid-svg-icons';

interface BookDetailsProps extends Book {
    onReadNowClick: () => void; // Function with no arguments
}

const BookDetails: React.FC<BookDetailsProps> = ({ title, description, authorname, readNowUrl, cover, rating, language, release_date, tags, epubfilesize, publishingdate, onReadNowClick }) => {
    const [isExpanded, setIsExpanded] = useState(false);


    const tagsString = tags.join(', ');


    // Function to toggle description view
    const toggleDescription = () => {
        setIsExpanded(!isExpanded);
    };

    // Render truncated or full description based on isExpanded state
    const truncatedDescription = description.length > 800
        ? description.substring(0, 800) + '...'
        : description;

        const getStarRating = (rating: number) => {
            const fullStars = Math.floor(rating);
            const hasHalfStar = rating % 1 !== 0;
            const totalStars = 5; // Total number of stars
        
            return Array.from({ length: totalStars }, (_, index) => {
              if (index < fullStars) {
                return <FontAwesomeIcon key={index} icon={solidStar} className="star" />;
              }
              if (index === fullStars && hasHalfStar) {
                return <FontAwesomeIcon key={index} icon={halfStar} className="star" />;
              }
              return <FontAwesomeIcon key={index} icon={regularStar} className="star empty" />;
            });
          };

          const formattedSize = (epubfilesize / 1024).toFixed(2) + ' KB';


    return (
        <>
        {/* Header */}
        <header className="bg-blue-600 text-white p-4 w-full shadow-md">
            <nav className="container mx-auto flex items-center justify-between flex-wrap">
                <div className="flex items-center  gap-2">
                <img className='w-12 h-10' src="/eicon.png" alt="logo" />
                <NavLink to="/Home" className="text-xl font-semibold">
                    ekindleLibrary
                </NavLink>
                </div>
                <div className="flex gap-4 items-center">
                {[
                    { label: 'Home', link: '/Home', icon: faHome },
                    // { label: 'Upload Book', link: '/FileUploadForm', icon: faUpload },
                ].map(({ label, link, icon }, index) => (
                    <NavLink
                    to={link}
                    key={index}
                    className={({ isActive }) =>
                        cx('flex items-center gap-2 hover:underline', { 'font-bold': isActive })
                    }
                    >
                    <FontAwesomeIcon icon={icon} className="" /> {/* Icon */}
                    {label}
                    </NavLink>
                ))}
                </div>
            </nav>
        </header>

        {/* go-back */}
        <div className='md:ps-24 md: pt-4 pb-2 ps-4'>
            <button 
            onClick={() => window.history.back()} 
            className=" text-gray-600 hover:text-gray-900 transition duration-300 flex items-center"
        >
            <FontAwesomeIcon icon={faChevronLeft} className='pe-1'/>
            Go back
        </button>
        </div>

            
            {/* Book Details */}
            <main className="container mx-auto p-6 flex flex-col md:flex-row gap-8">
            <div className="flex flex-col items-center justify-center w-full md:w-1/4">
                <img src={cover} alt={title} className="w-60 h-auto object-cover rounded-lg shadow-lg"/>
                <div className='pt-4'>
                    <button 
                        onClick={onReadNowClick} 
                        className="border-2 border-blue-600 text-blue-600 py-2 px-6 md:px-12 lg:px-20 rounded hover:bg-blue-600 hover:text-white transition duration-300"
                    >
                        Read Now
                    </button>
                </div>
            </div>
                <div className="flex-1 ps-2 pe-2">
                    <div className='items-baseline mb-2 md: w-100 w-38'>
                        <h1 className="text-3xl font-normal">{title}</h1>
                        <p className='text-1xl pt-2 text-slate-500'>eKindle Edition</p>
                    </div>
                    <div className='flex items-center'>
                        <p>by</p>
                        <p className='text-[#007185] ps-1'>{authorname}</p>
                    </div>
                    <div className='pt-1 flex items-center'>
                        <p>{rating}</p>
                        <div className="card-rating-container pt-1 ps-1">
                            {getStarRating(rating)}
                        </div>
                    </div>
                    <div className='py-4'>
                        <div className='border-y-2'>
                            <p className="text-gray-700 mb-4 text-wrap">
                                {isExpanded ? description : truncatedDescription}
                                {description.length > 800 && (
                                    <button 
                                        onClick={toggleDescription}
                                        className="text-blue-600 font-bold underline ml-1 flex items-center"
                                    >
                                        {isExpanded ? ' Show Less' : ' Show More '}
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className={`w-4 h-4 ml-1 transition-transform ${isExpanded ? 'rotate-180' : 'rotate-0'}`}
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M6.293 7.293a1 1 0 011.414 0L10 8.586l2.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </button>
                                )}
                            </p>
                            <div className='flex gap-2 pb-2'>
                                <p className='text-base text-slate-600'>Genres:</p>
                                <p className='font-medium'> {tagsString}</p>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-items-start pb-4 md:gap-32 gap-12 border-b-2'>
                        <div className=' grid justify-items-center'>
                            <p className='text-sm text-slate-600 text-center'>Publication date</p>
                            <FontAwesomeIcon icon={faCalendarAlt} className='w-6 h-6 p-2 text-slate-600' />
                            <p className='text-sm text-slate-600'>{publishingdate}</p>
                        </div>
                        <div className=' grid justify-items-center'>
                            <p className='text-sm text-slate-600'>Language</p>
                            <FontAwesomeIcon icon={faEarthAsia} className='w-6 h-6 p-2 text-slate-600' />
                            <p className='text-sm text-slate-600'>{language}</p>
                        </div>
                        <div className=' grid justify-items-center'>
                            <p className='text-sm text-slate-600'>File size</p>
                            <FontAwesomeIcon icon={faFileZipper} className='w-6 h-6 p-2 text-slate-600' />
                            <p className='text-sm text-slate-600'>{formattedSize}</p>
                        </div>
                    </div>
                    {/* <button 
                        onClick={onReadNowClick} 
                        className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
                    >
                        Read Now
                    </button> */}
                </div>
            </main>
            
            {/* <Footer /> */}
            <footer className="bg-blue-600 text-white p-4 w-full mt-auto">
                <div className="flex justify-center mt-2">
                    <NavLink to="/contact" className="flex items-center gap-2 hover:underline"> {/* Update to match App.tsx route */}
                        <FontAwesomeIcon icon={faEnvelope} /> Contact us
                    </NavLink>
                </div>
                <div className="text-center pt-2">
                    &copy; 2024 ekindlelibrary
                </div>
            </footer>
        </>
    );
};

export default BookDetails;
