import React, { useState } from 'react';
import './Contact.css'; // Make sure to import the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faEnvelope, faHome } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

const Contact = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false); // Loading state

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true); // Show loading during the API request

    try {
      const response = await fetch('https://workers.younus-tao-ai.workers.dev/contact', { // Update with your actual API endpoint
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (response.ok) {
        setSuccessMessage('Your message has been sent successfully!');
        setErrorMessage('');
        setFormData({ name: '', email: '', message: '' }); // Reset form on success
      } else {
        setErrorMessage(data.error || 'Failed to send message. Please try again.');
        setSuccessMessage('');
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please try again later.');
      setSuccessMessage('');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <>
      <header className="bg-blue-600 text-white p-4 w-full shadow-md">
        <nav className="container mx-auto flex items-center justify-between flex-wrap">
          <div className="flex items-center gap-2">
            <img className='w-12 h-10' src="/eicon.png" alt="logo" />
            <NavLink to="/" className="text-xl font-semibold">
              ekindleLibrary
            </NavLink>
          </div>
          <div className="flex gap-4 items-center">
            {[
              { label: 'Home', link: '/', icon: faHome },
            ].map(({ label, link, icon }, index) => (
              <NavLink
                to={link}
                key={index}
                className={({ isActive }) =>
                  cx('flex items-center gap-2 hover:underline', { 'font-bold': isActive })
                }
              >
                <FontAwesomeIcon icon={icon} className="" /> {/* Icon */}
                {label}
              </NavLink>
            ))}
          </div>
        </nav>
      </header>

      {/* go-back */}
      <div className='md:ps-24 md: pt-4 pb-2 ps-4'>
        <button 
          onClick={() => window.history.back()} 
          className=" text-gray-600 hover:text-gray-900 transition duration-300 flex items-center"
        >
          <FontAwesomeIcon icon={faChevronLeft} className='pe-1'/>
          Go back
        </button>
      </div>
      
      <div className='mt-14'>
        <div className="upload-form">
          <h1>Contact Us</h1>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          {successMessage && <p className="success-message">{successMessage}</p>}
          
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ddd' }}
              />
            </div>
            <button type="submit" className="submit-button main-btn" disabled={loading}>
              {loading ? 'Sending...' : 'Send Message'}
            </button>
          </form>
        </div>
      </div>

      <footer className="bg-blue-600 text-white p-4 w-full mt-auto">
        <div className="text-center">
          &copy; 2024 ekindleLibrary
        </div>
      </footer>
    </>
  );
};

export default Contact;
