import React, { useRef, useState, useEffect } from 'react';
import "./GenreSlider.css"; // Updated to TagSlider.css
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faTimes, faBars } from '@fortawesome/free-solid-svg-icons';
import Epubviewer from '../content/Epubviewer';

interface Tag {
  id: number;
  name: string;
}

interface TagSliderProps {
  tags: Tag[]; // Updated prop name
  onFilterChange: (selectedTags: string[]) => void; // Updated prop name
}

const GenreSlider: React.FC<TagSliderProps> = ({ tags, onFilterChange }) => { // Updated prop name
  const [selectedTags, setSelectedTags] = useState<string[]>([]); // Updated state name
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isFullPage, setFullPage] = useState(false);
  const [getStateBookurl, setStateBookurl] = useState("");
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleChange = (tagName: string) => { // Updated to tagName
    const newSelectedTags = selectedTags.includes(tagName)
      ? selectedTags.filter(name => name !== tagName)
      : [...selectedTags, tagName];
    setSelectedTags(newSelectedTags);
    onFilterChange(newSelectedTags);
    setIsSidebarOpen(false);
  };

  const handleFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      const objectURL = URL.createObjectURL(file);
      setStateBookurl(objectURL);
      handleOpenFullPage(objectURL);
    }
  };

  const handleOpenFullPage = (book: string) => {
    setStateBookurl(book);
    setFullPage(true);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseFullPage = () => {
    setFullPage(false);
    setStateBookurl("");
    document.body.style.overflow = '';
  };

  const handleOpenSidebar = () => {
    setIsSidebarOpen(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (isFullPage) {
    return (
      <div className="full-page-container z-20">
        {/* <button
          onClick={handleCloseFullPage}
          className="close-button"
        >
          <FontAwesomeIcon icon={faTimes} />
        </button> */}
        <Epubviewer Bookurl={getStateBookurl} close={handleCloseFullPage}/>
      </div>
    );
  }

  return (
    <div>
      {isMobile ? (
        <>
          <div className={`hamburger-menu pt-2 ${isSidebarOpen ? 'open' : ''}`} onClick={handleOpenSidebar}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
          <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
            <button onClick={handleCloseSidebar} className="sidebar-close-button">
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <div className="sidebar-content">
              <button className="epub-button" onClick={handleFileSelect}>
                <FontAwesomeIcon icon={faFileAlt} className="button-icon" />
                Open your EPUB
              </button>
              <input
                type="file"
                accept=".epub"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
              <h3 className="genre-slider-title">Filter by Tags</h3> {/* Updated title */}
              <div className="genre-list"> {/* Updated class name */}
                {tags.map(tag => ( // Updated prop name
                  <div key={tag.id} className="genre-item"> {/* Updated class name */}
                    <input
                      type="checkbox"
                      id={`genre-${tag.id}`} // Updated id prefix
                      name={tag.name}
                      checked={selectedTags.includes(tag.name)} // Updated state name
                      onChange={() => handleChange(tag.name)} // Updated handler
                      className="genre-checkbox" // Updated class name
                    />
                    <label htmlFor={`genre-${tag.id}`} className="genre-label"> {/* Updated class name */}
                      {tag.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="genre-slider-container"> {/* Updated class name */}
          <button className="epub-button" onClick={handleFileSelect}>
            <FontAwesomeIcon icon={faFileAlt} className="button-icon" />
            Open your EPUB
          </button>
          <input
            type="file"
            accept=".epub"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <h3 className="genre-slider-title">Filter by Tags</h3> {/* Updated title */}
          <div className="genre-list"> {/* Updated class name */}
            {tags.map(tag => ( // Updated prop name
              <div key={tag.id} className="genre-item"> {/* Updated class name */}
                <input
                  type="checkbox"
                  id={`genre-${tag.id}`} // Updated id prefix
                  name={tag.name}
                  checked={selectedTags.includes(tag.name)} // Updated state name
                  onChange={() => handleChange(tag.name)} // Updated handler
                  className="genre-checkbox" // Updated class name
                />
                <label htmlFor={`genre-${tag.id}`} className="genre-label"> {/* Updated class name */}
                  {tag.name}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default GenreSlider; // Updated export name
