// BookDetailsContainer.tsx
import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import BookDetails from './BookDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Epubviewer from './Epubviewer';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Book } from './types';
import cx from 'classnames'

const BookDetailsContainer: React.FC = () => {
    const location = useLocation();
    const state = location.state as Book;

    const [isFullPage, setFullPage] = useState(false);
    const [getStateBookurl, setStateBookurl] = useState("");

    if (!state) return <div>No book data available.</div>;

    const { title, description, readNowUrl, cover, authorname, rating, release_date, tags, language, epubfilesize, publishingdate} = state;

    const handleOpenFullPage = (book: any) => {
        setStateBookurl(book);
        setFullPage(true);
        document.body.style.overflow = 'hidden'; // Hide scrollbar
    };

    const handleCloseFullPage = () => {
        setFullPage(false);
        setStateBookurl("");
        document.body.style.overflow = ''; // Show scrollbar
    };

    if (isFullPage) {
        return (
            <>
            <div className="full-page-container">
                {/* <button
                    onClick={handleCloseFullPage}
                    className="close-button"
                >
            <FontAwesomeIcon icon={faTimes} />
            </button> */}
                <Epubviewer Bookurl={getStateBookurl} close={handleCloseFullPage}/>
            </div>
            </>
        );
    }

    return (<>
           
        <BookDetails
            title={title}
            description={description}
            readNowUrl={readNowUrl}
            cover={cover} 
            authorname={authorname}
            rating={rating}
            release_date={release_date}
            language={language}
            tags={tags}
            epubfilesize={epubfilesize}
            publishingdate={publishingdate}

            onReadNowClick={()=> handleOpenFullPage(readNowUrl)}    />
            </>
    );
};

export default BookDetailsContainer;
